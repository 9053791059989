import { Theme, createStyles } from "@material-ui/core/styles"

export default (theme: Theme) =>
  createStyles({
    "@global": {
      "#nprogress": {
        direction: "ltr",
        pointerEvents: "none",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: 3.5,
        zIndex: theme.zIndex.tooltip,
        backgroundColor: theme.palette.grey[200],
        "& .nprogress-bar": {
          position: "fixed",
          backgroundColor: theme.palette.secondary.light,
          top: 0,
          left: 0,
          right: 0,
          height: 3.5,
        },
        "& dd, & dt": {
          position: "absolute",
          top: 0,
          height: 3.5,
          boxShadow: `${theme.palette.secondary.light} 1px 0 6px 1px`,
          borderRadius: "100%",
          animation: "mui-nprogress-pulse 2s ease-out 0s infinite",
        },
        "& dd": {
          opacity: 0.6,
          width: 20,
          right: 0,
          clip: "rect(-6px,22px,14px,10px)",
        },
        "& dt": {
          opacity: 0.6,
          width: 180,
          right: -80,
          clip: "rect(-6px,90px,14px,-6px)",
        },
      },
      "@keyframes mui-nprogress-pulse": {
        "30%": {
          opacity: 0.6,
        },
        "60%": {
          opacity: 0,
        },
        to: {
          opacity: 0.6,
        },
      },
    },
  })
