import firebaseErrorCodesConstants from "config/constants/firebaseErrorCodes.constants"

export type FirebaseMessageObject = {
  code: string
  message: string
}

export default function errorMapper(
  messageObject: FirebaseMessageObject,
): string | null {
  return firebaseErrorCodesConstants[messageObject.code] || null
}
