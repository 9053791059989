import React from "react"
import { Avatar, Link, Box, Container, Typography } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import logo from "assets/logoCasaVerde.png"
import useStyles from "./Public.styles"

interface PublicLayoutProps extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element
}

export default function PublicLayout(props: PublicLayoutProps) {
  const { children } = props
  const classes = useStyles()

  return (
    <Container component="div" maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar} alt="Casa Verde" src={logo}>
          CV
        </Avatar>
        <main className={classes.main}>{children}</main>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          Copyright ©
          <Link color="inherit" href="/">
            {" "}
            Dessert Storm
          </Link>
          {` ${new Date().getFullYear()}.`}
        </Typography>
      </Box>
    </Container>
  )
}
