type ActionType = string
type Payload = any | undefined
type Action<Payload> = {
  [key: string]: Payload
}

export default function actionFactory(
  type: ActionType,
  payload?: Payload,
): Action<Payload> {
  return {
    type,
    payload,
  }
}
