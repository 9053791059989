import { useNavigate } from "@reach/router"
import { useMutation } from "react-query"
import { useFeedbackMessagesContext } from "components/feedback-message/FeedbackMessage"
import errorMapper, {
  FirebaseMessageObject,
} from "core/factories/errorMapper.factory"
import AuthService, { UserFormData } from "core/services/auth.services"
import firebaseConfig from "config/firebase.config"

export default function useCreateAccount() {
  const { setFeedbackMessagesState } = useFeedbackMessagesContext()
  const navigate = useNavigate()

  const { mutate, data: mutationData, ...userCreationMutation } = useMutation(
    AuthService.createUserOnFirebase,
    {
      onSuccess: () => {
        const { currentUser } = firebaseConfig.auth()
        navigate("/auth/create-account/associate-company", {
          state: {
            uid: currentUser?.uid || null,
          },
        })
      },
      onError: response => {
        const message = errorMapper(response as FirebaseMessageObject) || ""
        setFeedbackMessagesState({
          open: true,
          message,
          variant: "error",
        })
      },
      retry: false,
    },
  )

  const onSubmit = (userInfo: UserFormData) => {
    mutate({
      password: userInfo.password,
      email: userInfo.email,
      displayName: userInfo.displayName,
    })
  }

  return {
    onSubmit,
    ...userCreationMutation,
  }
}
