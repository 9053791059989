import { withStyles } from "@material-ui/core/styles"
import loadingSelector from "core/selectors/loading.selector"
import NProgress from "nprogress"
import { StateType } from "core/reducers"
import { ConnectedProps, connect } from "react-redux"
import styles from "./LoadingBar.styles"

NProgress.configure({
  template: `
    <div class="nprogress-bar" role="bar">
      <dt></dt>
      <dd></dd>
    </div>
  `,
})

function mapState(state: StateType) {
  return {
    store: {
      isLoading: loadingSelector([
        "FETCH_CREATE_USER",
        "FETCH_LOG_IN_USER",
        "CREATE_COMPANY",
        "GET_COMPANIES",
        "GET_COMPANY_BY_UID",
        "GET_INVENTORY_ITEMS",
      ])(state),
    },
  }
}

const connector = connect(mapState)

type LoadingBarProps = ConnectedProps<typeof connector>

function LoadingBar(props: LoadingBarProps) {
  const { store } = props
  if (store.isLoading) {
    NProgress.start()
  }

  if (!store.isLoading) {
    NProgress.done()
  }

  return null
}

export default connector(withStyles(styles)(LoadingBar))
