import React, { useState, createContext, useContext } from "react"
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core"
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  Warning as WarningIcon,
} from "@material-ui/icons/"
import clsx from "classnames"
import useStyles from "./FeedbackMessage.styles"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

type FeedbackMessagesContextState = {
  message: string
  variant: "error" | "info" | "warning" | "success"
  open: boolean
}

const initialFeedbackMessagesContextState: FeedbackMessagesContextState = {
  message: "",
  variant: "info",
  open: false,
}

type SetFeedbackMessagesStateFunction = React.Dispatch<
  React.SetStateAction<FeedbackMessagesContextState>
>

const setInitialFeedbackMessagesState: SetFeedbackMessagesStateFunction = prevState => ({
  ...prevState,
})

const FeedbackMessagesContext = createContext({
  feedbackMessagesState: initialFeedbackMessagesContextState,
  setFeedbackMessagesState: setInitialFeedbackMessagesState,
})
FeedbackMessagesContext.displayName = "FeedbackMessagesContext"

export default function FeedbackMessagesProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const classes = useStyles()
  const [feedbackMessagesState, setFeedbackMessagesState] = useState(
    initialFeedbackMessagesContextState,
  )
  const Icon = variantIcon[feedbackMessagesState.variant]
  const handleClose = () =>
    setFeedbackMessagesState(initialFeedbackMessagesContextState)

  return (
    <FeedbackMessagesContext.Provider
      value={{ feedbackMessagesState, setFeedbackMessagesState }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={feedbackMessagesState.open}
        onClose={handleClose}
        autoHideDuration={7500}
      >
        <SnackbarContent
          className={classes[feedbackMessagesState.variant]}
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {feedbackMessagesState.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
      {children}
    </FeedbackMessagesContext.Provider>
  )
}

export function useFeedbackMessagesContext() {
  const context = useContext(FeedbackMessagesContext)
  if (typeof context === "undefined") {
    throw new Error(
      "useFeedbackMessagesContext must be used within the FeedbackMessagesProvider",
    )
  }
  return context
}
