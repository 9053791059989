import React, { useEffect, useCallback } from "react"
import { RouteComponentProps, useNavigate } from "@reach/router"
import { useForm } from "react-hook-form"
import { TextField, Button, Typography, Paper } from "@material-ui/core"

import { CompanyType } from "models/companies.model"

import useCreateCompanyStore from "../hooks/useCreateCompanyStore.hook"
import useCreateCompanyActions from "../hooks/useCreateCompanyActions.hook"

import companyValidations from "./companyValidations"
import useStyles from "./CreateCompany.styles"

type CreateCompanyProps = RouteComponentProps

type FormData = CompanyType

export default function CreateAccount(props: CreateCompanyProps) {
  const navigate = useNavigate()
  const actions = useCreateCompanyActions()
  const store = useCreateCompanyStore()
  const classes = useStyles()

  const formFunctions = useForm<FormData>({
    validationSchema: companyValidations,
  })

  const onSubmit = formFunctions.handleSubmit((data: FormData) => {
    actions.createCompany(data)
  })

  const createdId = store.createdCompany?.id || null

  useEffect(
    useCallback(() => {
      if (createdId) {
        navigate(`/super-admin/company/${createdId}`)
      }
    }, [navigate, createdId]),
    [createdId],
  )

  // useEffect(
  //   useCallback(() => {
  //     if (store.duplicatedEmail === true) {
  //       formFunctions.setError("email", "duplicated", "Este correo ya existe")
  //     }
  //   }, [formFunctions, store.duplicatedEmail]),
  //   [store.duplicatedEmail],
  // )

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Crear Empresa
      </Typography>
      <Paper elevation={3} className={classes.paper}>
        <form noValidate onSubmit={onSubmit}>
          <div className="forms-block">
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.nit)}
              helperText={formFunctions.errors.nit?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="nit"
              label="NIT"
              name="nit"
              required
              fullWidth
              autoFocus
            />
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.name)}
              helperText={formFunctions.errors.name?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="name"
              label="Nombre"
              name="name"
              required
              fullWidth
            />
          </div>
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.publicName)}
            helperText={formFunctions.errors.publicName?.message || ""}
            disabled={store.isLoading}
            variant="outlined"
            margin="normal"
            name="publicName"
            label="Razón Social"
            id="publicName"
            required
            fullWidth
          />
          <div className="forms-block">
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.address)}
              helperText={formFunctions.errors.address?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="address"
              label="Dirección"
              name="address"
              required
              fullWidth
            />
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.city)}
              helperText={formFunctions.errors.city?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="city"
              label="Ciudad"
              name="city"
              required
              fullWidth
            />
          </div>
          <div className="forms-block">
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.state)}
              helperText={formFunctions.errors.state?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="state"
              label="Departamento"
              name="state"
              required
              fullWidth
            />
            <TextField
              inputRef={formFunctions.register}
              error={Boolean(formFunctions.errors.phone)}
              helperText={formFunctions.errors.phone?.message || ""}
              disabled={store.isLoading}
              variant="outlined"
              margin="normal"
              id="phone"
              label="Teléfono"
              name="phone"
              required
              fullWidth
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={store.isLoading}
            fullWidth
          >
            Crear empresa
          </Button>
        </form>
      </Paper>
    </div>
  )
}
