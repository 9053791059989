import createReducer from "core/factories/createReducer.factory"
import superAdminActionTypes from "../action-types/superAdmin.actionTypes"

export interface SuperAdminState {
  superAdmin: {
    isEnabled: boolean | null
  }
}

export const initialState: SuperAdminState = {
  superAdmin: {
    isEnabled: null,
  },
}

export function setIsSuperAdmin(state: SuperAdminState, { payload }: any) {
  return {
    ...state,
    superAdmin: payload,
  }
}

export default createReducer(initialState, {
  [superAdminActionTypes.CHECK_SUPERADMIN_SUCCESS]: setIsSuperAdmin,
  [superAdminActionTypes.CHECK_SUPERADMIN_FAILURE]: setIsSuperAdmin,
})
