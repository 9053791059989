import errorMessages from "config/errorMessages"
import * as yup from "yup"

export default yup.object().shape({
  fullName: yup.string().required(errorMessages.required),
  email: yup
    .string()
    .required(errorMessages.required)
    .email(errorMessages.email),
  password: yup.string().required(errorMessages.required),
})

export const companyAssociationShape = yup.object().shape({
  name: yup.string().required(errorMessages.required),
  nit: yup.string().required(errorMessages.required),
  address: yup
    .string()
    .notRequired()
    .default(null)
    .nullable(),
  city: yup
    .string()
    .notRequired()
    .default(null)
    .nullable(),
  phone: yup
    .string()
    .notRequired()
    .default(null)
    .nullable(),
  publicName: yup
    .string()
    .notRequired()
    .default(null)
    .nullable(),
  state: yup
    .string()
    .notRequired()
    .default(null)
    .nullable(),
  affiliateDiscount: yup
    .number()
    .typeError(errorMessages.required)
    .required(errorMessages.required)
    .min(0, errorMessages.minZero)
    .max(100, errorMessages.maxHundred),
})
