import { useCallback, useEffect } from "react"
import { useNavigate } from "@reach/router"
import useAuthenticationContext, {
  UserState,
} from "core/contexts/Authentication.context"
import AuthService from "core/services/auth.services"
import { useMutation } from "react-query"
import { useFeedbackMessagesContext } from "components/feedback-message/FeedbackMessage"
import errorMapper, {
  FirebaseMessageObject,
} from "core/factories/errorMapper.factory"

export default function useLoginHooks() {
  const navigate = useNavigate()
  const { setFeedbackMessagesState } = useFeedbackMessagesContext()
  const { setState: setUserState } = useAuthenticationContext()
  const { mutate, ...loginMutation } = useMutation(AuthService.logIn, {
    onError: error => {
      const message = errorMapper(error as FirebaseMessageObject) || ""
      setFeedbackMessagesState({
        open: true,
        message,
        variant: "error",
      })
    },
    onSuccess: data => {
      setUserState((prevState: UserState) => ({
        ...prevState,
        data: data.user?.toJSON() as User,
        isAuthenticated: true,
      }))
      navigate("/")
    },
    retry: false,
  })

  const onSubmitLogin = (email: string, password: string) => {
    mutate({ email, password })
  }

  return {
    ...loginMutation,
    onSubmitLogin,
  }
}
