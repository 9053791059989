import React, { useState } from "react"
import { RouteComponentProps, useLocation, Redirect } from "@reach/router"
import { Slide } from "@material-ui/core"
import { TransitionGroup } from "react-transition-group"

import AppHeader from "components/header/Header"
import AppDrawer from "components/drawer/Drawer"

import useAuthenticationContext from "core/contexts/Authentication.context"
import useStyles from "./Main.styles"

interface MainLayoutProps extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element
}

export default function MainLayout(props: MainLayoutProps) {
  const { children } = props
  const classes = useStyles()
  const location = useLocation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { user } = useAuthenticationContext()

  if (!user.isAuthenticated) {
    return <Redirect noThrow to="/auth/login" />
  }

  return (
    <div className={classes.root}>
      <AppHeader onMenuClick={() => setOpenDrawer(!openDrawer)} />
      <AppDrawer
        open={openDrawer}
        onOpenDrawer={() => setOpenDrawer(true)}
        onCloseDrawer={() => setOpenDrawer(false)}
      />
      <div className={classes.main}>
        <div className={classes.toolbar} />
        <TransitionGroup component={null}>
          <Slide direction="left" key={location.key} timeout={200}>
            <main className={classes.content}>{children}</main>
          </Slide>
        </TransitionGroup>
      </div>
    </div>
  )
}
