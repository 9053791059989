import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    root: {
      flexGrow: 1,
    },
    title: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1, 2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        maxWidth: "900px",
        margin: "0 auto",
      },
    },
    "@global": {
      ".forms-block": {
        display: "flex",
        flexDirection: "column",
        "& .MuiFormControl-root:last-of-type": {
          [theme.breakpoints.up("md")]: {
            marginLeft: theme.spacing(2),
          },
        },
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
        },
      },
    },
  }),
)
