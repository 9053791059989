import { useSelector } from "react-redux"

import getCreatedCompany from "core/selectors/company.selector"
import loadingSelector from "core/selectors/loading.selector"

export default function useCreateCompanyStore() {
  return {
    isLoading: useSelector(loadingSelector(["CREATE_COMPANY"])),
    createdCompany: useSelector(getCreatedCompany),
  }
}
