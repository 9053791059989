import React, { useEffect, useCallback } from "react"
import { RouteComponentProps } from "@reach/router"
import { Typography, Avatar } from "@material-ui/core"
import { WorkRounded as CompanyListIcon } from "@material-ui/icons"

import { CompanyType } from "models/companies.model"
import Table from "components/table/Table"

import useStyles from "./CompaniesList.styles"
import useCompaniesActions from "../hooks/useCompaniesActions.hook"
import useCompaniesStore from "../hooks/useCompaniesStore.hook"

type CompaniesListProps = RouteComponentProps

type CompanyIconProps = {
  company: CompanyType
}

function CompanyName(props: CompanyIconProps) {
  const classes = useStyles()
  const { company } = props
  if (company.photoUrl) {
    return (
      <div className={classes.companyNameRoot}>
        <Avatar alt={`Avatar n°${company.name}`} src={company.photoUrl} />
        <Typography variant="body1" component="h6">
          {company.name}
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.companyNameRoot}>
      <Avatar>
        <CompanyListIcon />
      </Avatar>
      <Typography variant="body1" component="h6">
        {company.name}
      </Typography>
    </div>
  )
}

export default function CompaniesList(props: CompaniesListProps) {
  const classes = useStyles()
  const store = useCompaniesStore()
  const actions = useCompaniesActions()

  const companiesLength = store.companies?.length || null

  useEffect(
    useCallback(() => {
      if (store.companies === null) {
        actions.getCompanies()
      }
    }, [actions, store.companies]),
    [companiesLength],
  )

  const columns = [
    {
      title: "Nombre",
      field: "name",
      render: (company: CompanyType) => <CompanyName company={company} />, // eslint-disable-line
    },
    {
      title: "NIT",
      field: "nit",
    },
    {
      title: "Estado",
      field: "status",
    },
  ]

  const data: CompanyType[] = store.companies || []

  return (
    <div className={classes.root}>
      <Table title="Compañias" columns={columns} data={data} />
    </div>
  )
}
