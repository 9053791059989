import errorMessages from "config/errorMessages"
import * as yup from "yup"

export default yup.object().shape({
  nit: yup.string().required(errorMessages.required),
  name: yup.string().required(errorMessages.required),
  publicName: yup.string().required(errorMessages.required),
  state: yup.string().required(errorMessages.required),
  city: yup.string().required(errorMessages.required),
  address: yup.string().required(errorMessages.required),
  phone: yup.string().required(errorMessages.required),
})
