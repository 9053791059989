import React from "react"
import { RouteComponentProps } from "@reach/router"

export default function UserListPage(props: RouteComponentProps) {
  return (
    <div>
      <h1>User List</h1>
    </div>
  )
}
