import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(1, 2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        maxWidth: "900px",
        margin: "0 auto",
      },
    },
    companyNameRoot: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "& .MuiTypography-root": {
        marginLeft: theme.spacing(2),
      },
    },
  }),
)
