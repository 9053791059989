import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"

import CompanyActions from "core/actions/company.actions"

export default function useCompaniesActions() {
  const dispatch = useDispatch()
  return bindActionCreators(
    {
      getCompanies: CompanyActions.getCompanies,
    },
    dispatch,
  )
}
