import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      zIndex: 10000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    ripple: {
      display: "inline-block",
      position: "relative",
      width: "160px",
      height: "160px",
      "& div": {
        position: "absolute",
        border: `6px solid ${theme.palette.primary.light}`,
        opacity: 1,
        borderRadius: "50%",
        animation: "$lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite",
      },
      "& div:nth-child(2)": {
        animationDelay: "-0.75s",
      },
    },
    "@keyframes lds-ripple": {
      "0%": {
        top: "80px",
        left: "80px",
        width: 0,
        height: 0,
        opacity: 1,
      },
      "100%": {
        top: "0px",
        left: "0px",
        width: "160px",
        height: "160px",
        opacity: 0,
      },
    },
  }),
)
