import React, { Fragment } from "react"
import { useForm } from "react-hook-form"
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core"
import { Redirect, RouteComponentProps } from "@reach/router"
import useStyles from "./AssociateCompany.styles"
import { companyAssociationShape } from "./userValidations"
import useAssociateCompanyHooks from "../hooks/useAssociateCompany.hook"

type AssociateAccountProps = RouteComponentProps<{
  location: { state: { uid: string } }
}>

type CompanyAssociationData = {
  name: string
  nit: string
  address: string | null
  city: string | null
  phone: string | null
  publicName: string | null
  state: string | null
  affiliateDiscount: number
  creationTicketCode: string
}

export default function AssociateCompany(props: AssociateAccountProps) {
  const { location } = props
  const classes = useStyles()
  const {
    shouldRegister,
    verifyNit,
    shouldAssociateNit,
    isLoading,
  } = useAssociateCompanyHooks()

  const formFunctions = useForm<CompanyAssociationData>({
    validationSchema: companyAssociationShape,
  })

  const onSubmitHookForm = formFunctions.handleSubmit(
    (data: CompanyAssociationData) => {
      console.log(data)
    },
  )

  if (!location?.state.uid) {
    return <Redirect noThrow to="/not-found" />
  }

  return (
    <Fragment>
      <Typography component="h1" variant="h4" className={classes.title}>
        Asociar Empresa
      </Typography>
      <Typography component="p" variant="body1" className={classes.title}>
        Para continuar con el registro, debemos asociar una empresa a tu perfil.
        Digita el NIT de la empresa registrada acontinuación
      </Typography>
      <form noValidate onSubmit={verifyNit}>
        <TextField
          variant="outlined"
          margin="normal"
          id="nit"
          label="NIT"
          name="nit"
          required
          fullWidth
          autoFocus
        />
        {shouldAssociateNit && (
          <Typography component="p" variant="caption" className={classes.title}>
            El NIT es de una empresa válida. Estamos asociando tu cuenta a esta
            empresa...
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? (
            <CircularProgress size={32} color="inherit" />
          ) : (
            "Verificar NIT"
          )}
        </Button>
      </form>
      {shouldRegister && (
        <form noValidate onSubmit={onSubmitHookForm}>
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.nit)}
            helperText={formFunctions.errors.nit?.message || ""}
            variant="outlined"
            margin="normal"
            id="nit"
            label="NIT"
            name="nit"
            required
            fullWidth
            autoFocus
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.nit)}
            helperText={formFunctions.errors.nit?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            id="name"
            label="Nombre de Empresa"
            name="name"
            required
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.publicName)}
            helperText={formFunctions.errors.publicName?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="publicName"
            label="Razón Social"
            id="publicName"
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.address)}
            helperText={formFunctions.errors.address?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="address"
            label="Dirección"
            id="address"
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.city)}
            helperText={formFunctions.errors.city?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="city"
            label="Ciudad"
            id="city"
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.state)}
            helperText={formFunctions.errors.state?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="state"
            label="Departamento"
            id="state"
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.phone)}
            helperText={formFunctions.errors.phone?.message || ""}
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="phone"
            label="Teléfono"
            id="phone"
            fullWidth
          />
          <TextField
            inputRef={formFunctions.register}
            error={Boolean(formFunctions.errors.affiliateDiscount)}
            helperText={
              formFunctions.errors.affiliateDiscount?.message ||
              "Porcentaje de 0 a 100"
            }
            disabled={isLoading}
            variant="outlined"
            margin="normal"
            name="affiliateDiscount"
            label="Descuento para el Afiliado"
            id="affiliateDiscount"
            type="number"
            required
            fullWidth
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isLoading}
            fullWidth
          >
            Asociar Empresa
          </Button>
        </form>
      )}
    </Fragment>
  )
}
