import {
  DashboardRounded as DashboardIcon,
  BusinessRounded as CompaniesIcon,
  GroupRounded as UsersIcon,
  LocalOfferRounded as InventoryIcon,
} from "@material-ui/icons"

export type NavigationPageType = {
  url: string
  title: string
  isDefault: boolean
}

export type NavigationRouteType = {
  url: string
  title: string
  Icon: any
  isDefault: boolean
  pages: NavigationPageType[] | null
}

type NavigationMenu = {
  [key: string]: {
    mountAt: string
    routes: NavigationRouteType[]
  }
}

const navigation: NavigationMenu = {
  main: {
    mountAt: "/",
    routes: [
      {
        url: "/",
        title: "Panel de control",
        Icon: DashboardIcon,
        isDefault: true,
        pages: null,
      },
      {
        url: "/inventory",
        title: "Inventario",
        Icon: InventoryIcon,
        isDefault: false,
        pages: [
          { url: "/inventory/medicine", title: "Medicina", isDefault: false },
        ],
      },
    ],
  },
  superAdmin: {
    mountAt: "/super-admin/",
    routes: [
      {
        url: "company",
        title: "Empresas",
        Icon: CompaniesIcon,
        isDefault: true,
        pages: null,
      },
      {
        url: "users",
        title: "Usuarios",
        Icon: UsersIcon,
        isDefault: false,
        pages: null,
      },
    ],
  },
}

export function useNavigationMenu(module: "superAdmin" | "main") {
  return navigation[module]
}

export default navigation
