import React from "react"
import { Typography, Button } from "@material-ui/core"
import { RouteComponentProps, Redirect, Link } from "@reach/router"

export default function AccountCreatedSuccessfully(
  props: RouteComponentProps<{
    location: { state: { uid: string; fullName: string } }
  }>,
) {
  const { location } = props
  let fullName = ""

  if (!location?.state?.uid) {
    return <Redirect noThrow to="/not-found" />
  }

  fullName = location?.state?.fullName

  return (
    <div>
      <Typography
        component="h4"
        align="center"
        color="inherit"
        variant="h4"
        gutterBottom
      >
        {`¡${fullName}, tu cuenta se ha creado correctamente!`}
      </Typography>
      <Typography
        align="center"
        color="inherit"
        variant="h5"
        component="h5"
        gutterBottom
      >
        En breve recibirás un mensaje para confirmar tu correo
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/auth/login"
        fullWidth
      >
        Iniciar Sesion
      </Button>
    </div>
  )
}
