import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { Provider as ReduxProvider } from "react-redux"
import { CssBaseline } from "@material-ui/core"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import Routes from "routes/Routes.main"
import theme from "styles/theme"
import setupStore from "config/store.config"
import LoadingBar from "components/loading-bar/LoadingBar"
import FeedbackMessage from "components/feedback-message/FeedbackMessage"
import queryClient from "config/queryClient.config"
import { AuthenticationContextProvider } from "core/contexts/Authentication.context"

export default function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <FeedbackMessage>
            <AuthenticationContextProvider>
              <ReduxProvider store={setupStore()}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Dessert Storm</title>
                </Helmet>
                <LoadingBar />
                <Routes />
              </ReduxProvider>
            </AuthenticationContextProvider>
          </FeedbackMessage>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
