import React from "react"
import { Link } from "@reach/router"
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Avatar,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  AccountCircleRounded as ProfileIcon,
} from "@material-ui/icons"
import LogoutButton from "components/logout-button/LogoutButton"
import logo from "assets/logoFooter.png"
import useStyles from "./Header.styles"

interface AppHeaderProps {
  onMenuClick: () => void
  isSuperAdmin?: boolean
}

export default function AppHeader(props: AppHeaderProps) {
  const { onMenuClick, isSuperAdmin } = props
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {isMobile && (
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Avatar src={logo} alt="Casa Verde" className={classes.logo}>
          CV
        </Avatar>
        <Typography variant="h6" className={classes.title}>
          Casa Verde
        </Typography>
        {!isSuperAdmin && (
          <IconButton component={Link} to="/profile" color="inherit">
            <ProfileIcon />
          </IconButton>
        )}
        <LogoutButton />
      </Toolbar>
    </AppBar>
  )
}

AppHeader.defaultProps = {
  isSuperAdmin: false,
}
