import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    grid: {
      minHeight: "80vh",
      border: 0,
      backgroundColor: "white",
      "& .rdg-header-row": {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
    gridRow: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      textTransform: "capitalize",
      backgroundColor: "white",
      color: theme.palette.text.primary,
      "&:hover": {
        backgroundColor: "white",
        color: theme.palette.text.primary,
      },
    },
    cell: {
      border: 0,
    },
    headerCell: {
      border: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    imageCell: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    productRoot: {
      flexGrow: 1,
      textTransform: "capitalize",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    listRoot: {
      height: "85vh",
      overflowY: "auto",
    },
  }),
)

export const useNameCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      lineHeight: 1,
      height: "100%",
      justifyContent: "center",
    },
    nameContainer: {},
    info: {
      "& span": {
        marginRight: "1rem",
        "& :last-of-type": {
          marginRight: 0,
        },
      },
    },
    name: {
      lineHeight: 1.2,
      width: "100%",
      fontSize: "1.1rem",
      fontWeight: 500,
    },
  }),
)
