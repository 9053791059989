import axios from "config/axios"

export default class MedicineServices {
  static getMedicine(companyId: string, page: number, pageSize: number) {
    return axios.get("/medicine", {
      params: {
        companyId,
        page,
        pageSize,
        paginate: 1,
      },
    })
  }
}
