import createReducer from "core/factories/createReducer.factory"
import inventoryItemsActionTypes from "core/action-types/medicine.actionTypes"
import BigNumber from "bignumber.js"

export interface LaboratoryAttributes {
  id: string
  name: string
  companyId: string
  relationCode: string | null // present on all models to make relations on data mapping and seeding
}

export interface PresentationAttributes {
  id: string
  name: string
  companyId: string
  relationCode: string | null // present on all models to make relations on data mapping and seeding
}

export interface IvaAttributes {
  id: string
  name: string
  value: number
  createdAt: string
  deletedAt: string | null
  updatedAt: string | null
  relationCode: string | null
}

export type MedicineAttributes = {
  id: string
  internalCode: number
  name: string
  ean: string | null
  image: string | null
  notas: string | null
  companyId: string
  qty: number
  relationCode: string | null
  ivaId: string
  laboratoryId: string
  presentationId: string
  iva: IvaAttributes
  laboratory: LaboratoryAttributes
  presentation: PresentationAttributes
  createdAt: string
  updatedAt: string | null
  deletedAt: string | null
  basePrice: BigNumber
  affiliatePrice: BigNumber
  buyingPrice: BigNumber
  sellingPercentage: number
}

export type MedicineStore = {
  items: MedicineAttributes[]
  startAt: number
}

export const initialState: MedicineStore = {
  items: [],
  startAt: 1,
}

export function items(state: MedicineStore, { payload }: any) {
  const mappedRows = payload.rows.map((item: Record<string, unknown>) => ({
    ...item,
    basePrice: new BigNumber(item.basePrice as number),
    affiliatePrice: new BigNumber(item.affiliatePrice as number),
    buyingPrice: new BigNumber(item.buyingPrice as number),
  }))

  return {
    ...state,
    items: [...state.items, ...mappedRows],
    startAt: state.startAt + 1,
  }
}

export default createReducer(initialState, {
  [inventoryItemsActionTypes.GET_MEDICINE_SUCCESS]: items,
})
