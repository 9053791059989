import React, { Fragment, useState } from "react"
import { Link, useMatch, RouteComponentProps } from "@reach/router"
import {
  Hidden,
  Drawer,
  List,
  ListItemIcon,
  ListItem as MaterialListItem,
  ListItemText,
  SwipeableDrawer,
  ListItemProps,
  Collapse,
} from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import {
  useNavigationMenu,
  NavigationPageType,
} from "components/drawer/navigation"
import useStyles from "./Drawer.styles"

interface AppDrawerProps extends RouteComponentProps<any> {
  open: boolean
  onCloseDrawer: () => void
  onOpenDrawer: () => void
  isSuperAdmin?: boolean
}

interface ItemProps extends ListItemProps {
  item: any
  isSuperAdmin?: boolean
  isNested?: boolean
}

function ListItem(props: ItemProps) {
  const { item, isSuperAdmin, isNested } = props
  const menu = useNavigationMenu(isSuperAdmin ? "superAdmin" : "main")
  const match = useMatch(item.pages ? item.url : `${menu.mountAt}${item.url}`)
  const isSubmenuOpen = window.location.pathname.includes(menu.mountAt)
  const [isOpen, setIsOpen] = useState(Boolean(match))
  const classes = useStyles()

  if (item.pages) {
    return (
      <Fragment>
        <MaterialListItem
          button
          selected={Boolean(match)}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ListItemIcon>
            <item.Icon />
          </ListItemIcon>
          <ListItemText primary={item.title} />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </MaterialListItem>
        <Collapse in={isOpen || isSubmenuOpen} timeout="auto" unmountOnExit>
          <List>
            {item.pages.map((page: NavigationPageType) => {
              return (
                <ListItem
                  key={page.url}
                  item={page}
                  isSuperAdmin={isSuperAdmin}
                  isNested
                />
              )
            })}
          </List>
        </Collapse>
      </Fragment>
    )
  }

  return (
    <MaterialListItem
      button
      selected={Boolean(match)}
      component={Link}
      to={item.url}
      className={isNested ? classes.nestedListItem : ""}
      dense={isNested}
    >
      {item.Icon && (
        <ListItemIcon>
          <item.Icon />
        </ListItemIcon>
      )}
      <ListItemText primary={item.title} />
    </MaterialListItem>
  )
}

function AppDrawer(props: AppDrawerProps) {
  const { open, onCloseDrawer, onOpenDrawer, isSuperAdmin } = props
  const classes = useStyles()

  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

  const menu = useNavigationMenu(isSuperAdmin ? "superAdmin" : "main")

  const items = menu.routes.map(item => (
    <ListItem item={item} key={item.url} isSuperAdmin={isSuperAdmin} />
  ))

  return (
    <nav className={classes.drawer} aria-label="main-menu">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          open={open}
          onOpen={onOpenDrawer}
          onClose={onCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          data-testid="drawerComponentDesktop"
        >
          <div className={classes.toolbar} />
          <List>{items}</List>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          data-testid="drawerComponent"
          open
        >
          <div className={classes.toolbar} />
          <List>{items}</List>
        </Drawer>
      </Hidden>
    </nav>
  )
}

ListItem.defaultProps = {
  isNested: false,
  isSuperAdmin: false,
}

AppDrawer.defaultProps = {
  isSuperAdmin: false,
}

export default AppDrawer
