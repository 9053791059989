import { compose, createStore, applyMiddleware, Store } from "redux"
import thunk from "redux-thunk"
import reducers from "core/reducers"

export default function setUpStore(initialState = {}) {
  let store: Store // eslint-disable-line

  const createStoreWithMiddleware = compose(applyMiddleware(thunk))(createStore)

  if (process.env.NODE_ENV === "development") {
    store = createStore(
      reducers,
      initialState,
      compose(
        applyMiddleware(thunk),
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ // eslint-disable-line
          ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
          : (f: any) => f,
      ),
    )
  } else {
    store = createStoreWithMiddleware(reducers, initialState)
  }
  return store
}
