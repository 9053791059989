import actionFactory from "core/factories/action.factory"
import itemsActionTypes from "../action-types/medicine.actionTypes"
import MedicineServices from "../services/medicine.services"
import { DispatchFunction } from "../types/misc"

export default class MedicineActions {
  static getMedicineItems(): (
    dispatch: DispatchFunction,
    getState: any,
  ) => Promise<any> {
    return (dispatch, getState) => {
      dispatch(actionFactory(itemsActionTypes.GET_MEDICINE_REQUEST))
      const { startAt } = getState().inventoryItemsStore
      return MedicineServices.getMedicine("", startAt, 50)
        .then(response => {
          const { data } = response
          dispatch(actionFactory(itemsActionTypes.GET_MEDICINE_SUCCESS, data))
        })
        .catch(error => {
          console.error(error)
          dispatch(actionFactory(itemsActionTypes.GET_MEDICINE_FAILURE, error))
        })
    }
  }
}
