import { createMuiTheme } from "@material-ui/core/styles"
import { grey, blue, pink } from "@material-ui/core/colors"

export default createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#5cba3c",
    },
  },
})
