export type CompanyType = {
  id: string | null
  nit: string | null
  name: string | null
  publicName: string | null
  state: string | null
  city: string | null
  address: string | null
  phone: string | null
  photoUrl: string | null
  status: "disabled" | "enabled"
}

export default class Company {
  private id: string | null = null

  private nit: string | null = null

  private name: string | null = null

  private publicName: string | null = null

  private state: string | null = null

  private city: string | null = null

  private address: string | null = null

  private phone: string | null = null

  private photoUrl: string | null = null

  private status: "disabled" | "enabled" = "enabled"

  constructor({
    nit,
    name,
    publicName,
    state,
    city,
    address,
    phone,
    photoUrl,
    status = "enabled",
  }: any) {
    this.nit = nit
    this.name = name
    this.publicName = publicName
    this.state = state
    this.city = city
    this.address = address
    this.phone = phone
    this.photoUrl = photoUrl
    this.status = status
  }

  changeStatus(newStatus: "enabled" | "disabled") {
    this.status = newStatus
  }

  getCompany(): CompanyType {
    return {
      id: this.id,
      nit: this.nit,
      name: this.name,
      publicName: this.publicName,
      state: this.state,
      city: this.city,
      address: this.address,
      phone: this.phone,
      photoUrl: this.photoUrl,
      status: this.status,
    }
  }

  updateCompany({
    nit,
    name,
    publicName,
    state,
    city,
    address,
    phone,
    photoUrl,
  }: CompanyType) {
    this.nit = nit || this.nit
    this.name = name || this.name
    this.publicName = publicName || this.publicName
    this.state = state || this.state
    this.city = city || this.city
    this.address = address || this.address
    this.phone = phone || this.phone
    this.photoUrl = photoUrl || this.photoUrl
  }
}
