import { bindActionCreators } from "redux"
import { useDispatch } from "react-redux"

import CompanyActions from "core/actions/company.actions"

export default function useCreateCompanyActions() {
  const dispatch = useDispatch()
  return bindActionCreators(
    {
      createCompany: CompanyActions.createCompany,
    },
    dispatch,
  )
}
