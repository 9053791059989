import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import MedicineActions from "core/actions/medicine.actions"
import {
  getMedicineItemsSelector,
  getMedicinePaginationSelector,
} from "core/selectors/medicine.selector"
import { getCompanyForUser } from "core/selectors/company.selector"
import loadingSelector from "core/selectors/loading.selector"

export function useMedicineActions() {
  return bindActionCreators(
    {
      getMedicine: MedicineActions.getMedicineItems,
    },
    useDispatch(),
  )
}

export function useMedicineStore() {
  return {
    medicineItems: useSelector(getMedicineItemsSelector),
    companyForuser: useSelector(getCompanyForUser),
    isLoading: useSelector(
      loadingSelector(["GET_COMPANY_BY_UID", "GET_INVENTORY_ITEMS"]),
    ),
    startAt: useSelector(getMedicinePaginationSelector),
  }
}
