import FullScreenLoader from "components/full-screen-loader/FullScreenLoader"
import firebaseConfig from "config/firebase.config"
import AuthService from "core/services/auth.services"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useQuery } from "react-query"

export interface UserState {
  isAuthenticated: boolean
  data: User | null
}

export interface InitialContextState {
  user: UserState
  setState: React.Dispatch<React.SetStateAction<UserState>>
}

export const initialAuthContextState: InitialContextState = {
  user: {
    isAuthenticated: false,
    data: null,
  },
  setState: prevState => ({ ...prevState }),
}

const AuthenticationContext = createContext(initialAuthContextState)
AuthenticationContext.displayName = "AuthenticationContext"

export function AuthenticationContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [userState, setUserState] = useState<UserState>({
    isAuthenticated: false,
    data: null,
  })

  const isPublicLayout = window.location.pathname.includes("/auth")

  const { data: queryResultData } = useQuery({
    queryKey: ["getUserByUid", userState.data?.uid || null],
    queryFn: () =>
      AuthService.getUserFromDatabaseByUid(userState.data?.uid || null),
    enabled: !!userState.data?.uid,
    retry: false,
    onError: () => {
      const { currentUser } = firebaseConfig.auth()
      if (currentUser && isPublicLayout) {
        setIsLoading(false)
      }
    },
    onSuccess: () => {
      if (queryResultData?.data) {
        setUserState(previousValues => ({
          ...previousValues,
          isAuthenticated: true,
          data: {
            ...previousValues.data,
            ...queryResultData.data,
          },
        }))
      }
      setIsLoading(false)
    },
  })

  useEffect(
    useCallback(() => {
      firebaseConfig.auth().onAuthStateChanged(userInfo => {
        if (userInfo) {
          const user = userInfo?.toJSON() as User
          setUserState(previousValues => ({
            ...previousValues,
            data: { ...user },
          }))
        } else {
          setIsLoading(false)
        }
      })
    }, []),
    [],
  )

  return (
    <AuthenticationContext.Provider
      value={{ user: userState, setState: setUserState }}
    >
      {isLoading && <FullScreenLoader />}
      {!isLoading && children}
    </AuthenticationContext.Provider>
  )
}

export default function useAuthenticationContext() {
  const context = useContext(AuthenticationContext)
  if (typeof context === "undefined") {
    throw new Error(
      "useAuthenticationContext must be used within the AuthenticationContextProvider",
    )
  }
  return context
}
