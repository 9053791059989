import React from "react"
import { IconButton } from "@material-ui/core"
import { ExitToAppRounded as LogoutIcon } from "@material-ui/icons"
import useLogoutButtonHooks from "components/logout-button/logoutButton.hook"

export default function LogoutButton() {
  const { doSignout } = useLogoutButtonHooks()

  return (
    <IconButton color="inherit" onClick={doSignout}>
      <LogoutIcon />
    </IconButton>
  )
}
