import firebase from "firebase"
import { CompanyType } from "models/companies.model"

export default {
  toFirestore(company: CompanyType): firebase.firestore.DocumentData {
    return company
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): CompanyType {
    const data = snapshot.data(options)
    const company = {
      id: data.id,
      nit: data.nit,
      name: data.name,
      publicName: data.publicName,
      state: data.state,
      city: data.city,
      address: data.address,
      phone: data.phone,
      photoUrl: data.photoUrl,
      status: data.status,
    }
    return company
  },
}
