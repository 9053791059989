/**
 * Create reducer, this is boiler plate code to reduce
 * complexity on the reducers, we don't want to have a 200
 * lines of code of switch.
 *
 * @param {Object} initialState Initial state for the reducer
 * @param {Object} handlers containing key, pair values of the
 * actions (key) and the function to reduce (pair)
 */

export default function createReducer<S, A extends { type: string }>(
  initialState: S,
  handlers: {
    [P in A["type"]]?: A extends { type: P }
      ? (state: S, action: A) => S
      : never
  },
) {
  return (state: S = initialState, action: A): S => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return (handlers as any)[action.type](state, action)
    }
    return state
  }
}
