import { CompanyType } from "models/companies.model"
import actionFactory from "core/factories/action.factory"
import companyActionTypes from "core/action-types/company.actionTypes"
import CompanyServices from "core/services/company.services"
import { DispatchFunction } from "core/types/misc"

export default class CompanyActions {
  static createCompany(
    companyInfo: CompanyType,
  ): (dispatch: DispatchFunction) => Promise<any> {
    return dispatch => {
      dispatch(actionFactory(companyActionTypes.CREATE_COMPANY_REQUEST))
      return CompanyServices.createCompany(companyInfo)
        .then(docRef => {
          dispatch(
            actionFactory(companyActionTypes.CREATE_COMPANY_SUCCESS, docRef),
          )
          return Promise.resolve(docRef)
        })
        .catch(error => {
          dispatch(
            actionFactory(
              companyActionTypes.CREATE_COMPANY_FAILURE,
              error.toString(),
            ),
          )
        })
    }
  }

  static getCompanies(): (dispatch: DispatchFunction) => Promise<any> {
    return dispatch => {
      dispatch(actionFactory(companyActionTypes.GET_COMPANIES_REQUEST))
      return CompanyServices.getCompanies()
        .then(result => {
          dispatch(
            actionFactory(companyActionTypes.GET_COMPANIES_SUCCESS, result),
          )
        })
        .catch(error => {
          dispatch(
            actionFactory(companyActionTypes.GET_COMPANIES_FAILURE, error),
          )
        })
    }
  }

  static getCompanyById(companyUid: string) {
    return (dispatch: DispatchFunction) => {
      dispatch(actionFactory(companyActionTypes.GET_COMPANY_BY_UID_REQUEST))
      return CompanyServices.getCompanyById(companyUid)
        .then(result => {
          dispatch(
            actionFactory(companyActionTypes.GET_COMPANY_BY_UID_SUCCESS, {
              ...result.data(),
              id: companyUid,
            }),
          )
        })
        .catch(error => {
          dispatch(
            actionFactory(companyActionTypes.GET_COMPANY_BY_UID_FAILURE, error),
          )
        })
    }
  }
}
