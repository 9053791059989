import React, { Fragment, useCallback, useEffect } from "react"
import { Link as RouterLink, RouteComponentProps } from "@reach/router"
import { useForm } from "react-hook-form"
import { TextField, Button, Typography } from "@material-ui/core"
import userValidations from "modules/create-account/components/userValidations"
import useCreateAccount from "modules/create-account/hooks/useCreateAccount.hook"
import errorMapper, {
  FirebaseMessageObject,
} from "core/factories/errorMapper.factory"
import useStyles from "./CreateAccount.styles"

type ICreateAccountProps = RouteComponentProps

export type CreateAccountFormData = {
  fullName: string
  email: string
  password: string
}

export default function CreateAccount(props: ICreateAccountProps) {
  const classes = useStyles()

  const formFunctions = useForm<CreateAccountFormData>({
    validationSchema: userValidations,
  })
  const { onSubmit, isLoading, isError, error } = useCreateAccount()

  const onSubmitHookForm = formFunctions.handleSubmit(
    (data: CreateAccountFormData) => {
      onSubmit({
        displayName: data.fullName,
        email: data.email,
        password: data.password,
      })
    },
  )

  useEffect(
    useCallback(() => {
      if (isError && error) {
        if (
          (error as FirebaseMessageObject).code === "auth/email-already-in-use"
        ) {
          formFunctions.setError(
            "email",
            "",
            errorMapper(error as FirebaseMessageObject) || "",
          )
        }
      }
    }, [error, formFunctions, isError]),
    [isError],
  )

  return (
    <Fragment>
      <Typography component="h1" variant="h4" className={classes.title}>
        Crear Cuenta
      </Typography>
      <form noValidate onSubmit={onSubmitHookForm}>
        <TextField
          inputRef={formFunctions.register}
          error={Boolean(formFunctions.errors.fullName)}
          helperText={formFunctions.errors.fullName?.message || ""}
          disabled={isLoading}
          variant="outlined"
          margin="normal"
          id="fullName"
          label="Nombre"
          name="fullName"
          required
          fullWidth
          autoFocus
        />
        <TextField
          inputRef={formFunctions.register}
          error={Boolean(formFunctions.errors.email)}
          helperText={formFunctions.errors.email?.message || ""}
          disabled={isLoading}
          variant="outlined"
          margin="normal"
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          required
          fullWidth
        />
        <TextField
          inputRef={formFunctions.register}
          error={Boolean(formFunctions.errors.password)}
          helperText={formFunctions.errors.password?.message || ""}
          disabled={isLoading}
          variant="outlined"
          margin="normal"
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          required
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={isLoading}
          fullWidth
        >
          Crear cuenta
        </Button>
        <Button component={RouterLink} to="/" fullWidth>
          Iniciar Sesión
        </Button>
      </form>
    </Fragment>
  )
}
