import React from "react"
import { RouteComponentProps, Router } from "@reach/router"
import Medicine from "modules/inventory/medicine/Medicine.page"

export default function InventoryRouter(props: RouteComponentProps) {
  return (
    <Router>
      <Medicine default path="medicine" />
    </Router>
  )
}
