import React from "react"
import { Fab, Zoom, Tooltip } from "@material-ui/core"
import { Link, useLocation } from "@reach/router"
import { Add as AddIcon } from "@material-ui/icons"
import { useNavigationMenu } from "components/drawer/navigation"
import useStyles from "./SuperAdminFab.styles"

export default function SuperAdminFab() {
  const classes = useStyles()
  const location = useLocation()
  const superAdminMenu = useNavigationMenu("superAdmin")
  const option = superAdminMenu.routes.find(item =>
    item.url.includes(location.pathname),
  )
  const title = `Crear ${option ? option.title : ""}`

  return (
    <Zoom key={location.pathname} in timeout={200} unmountOnExit>
      <Tooltip title={title} aria-label="add" placement="left">
        <Fab
          aria-label={title}
          className={classes.fab}
          component={Link}
          to={`${location.pathname}/create`}
          color="primary"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </Zoom>
  )
}
