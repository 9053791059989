import firebase from "config/firebase.config"
import Company, { CompanyType } from "models/companies.model"
import { collectionPaths } from "config/constants/firebase"
import companyConverter from "core/converters/company.converter"
import { publicAxios } from "config/axios"

export default class CompanyServices {
  static createCompany(companyInfo: CompanyType) {
    return new Promise((resolve, reject) => {
      const collectionRef = firebase
        .firestore()
        .collection(collectionPaths.company)
        .withConverter(companyConverter)
      collectionRef
        .where("nit", "==", companyInfo.nit)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.empty) {
            collectionRef.add(companyInfo).then(docRef => {
              resolve(docRef)
            })
          } else {
            reject(new Error("nit-already-exists"))
          }
        })
        .catch(error => reject(error))
    })
  }

  static getCompanyById(companyId: string) {
    return firebase
      .firestore()
      .collection(collectionPaths.company)
      .withConverter(companyConverter)
      .doc(companyId)
      .get()
  }

  static getCompanies() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(collectionPaths.company)
        .get()
        .then(querySnapshot => {
          const companies: CompanyType[] = []
          if (querySnapshot.empty) {
            return resolve(companies)
          }
          querySnapshot.forEach(doc => {
            const company = new Company(doc.data())
            companies.push(company.getCompany())
          })
          return resolve(companies)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  static verifyCompany(nit: string) {
    return publicAxios.get(`/companies/verify/${nit}`)
  }
}
