import { useState } from "react"
import { useQuery } from "react-query"
import AuthenticationService from "core/services/auth.services"
import useAuthenticationContext, {
  initialAuthContextState,
} from "core/contexts/Authentication.context"
import { useNavigate } from "@reach/router"

export default function useLogoutButtonHooks() {
  const [enabled, setIsEnabled] = useState(false)
  const { setState: setUserState } = useAuthenticationContext()
  const navigate = useNavigate()
  const query = useQuery("doLogout", AuthenticationService.logOut, {
    enabled,
    retry: false,
    onSuccess: () => {
      console.log("finished signout")
      setUserState(initialAuthContextState.user)
      navigate("/auth/login")
    },
  })

  const doSignout = () => {
    setIsEnabled(true)
    console.log("started signout")
  }

  return {
    doSignout,
    ...query,
  }
}
