import React, { useState } from "react"
import { RouteComponentProps, useLocation, Redirect } from "@reach/router"
import { Slide } from "@material-ui/core"
import { TransitionGroup } from "react-transition-group"

import SuperAdminFab from "components/super-admin-fab/SuperAdminFab"
import AppHeader from "components/header/Header"
import AppDrawer from "components/drawer/Drawer"

import useAuthenticationContext from "core/contexts/Authentication.context"
import useStyles from "./SuperAdmin.styles"

interface SuperAdminLayoutProps extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element
}

export default function SuperAdminLayout(props: SuperAdminLayoutProps) {
  const { children } = props
  const classes = useStyles()
  const location = useLocation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { user } = useAuthenticationContext()

  if (user.isAuthenticated && user.data?.role !== "super-admin") {
    return <Redirect noThrow from="/super-admin/" to="/" />
  }

  return (
    <div className={classes.root}>
      <AppHeader onMenuClick={() => setOpenDrawer(!openDrawer)} isSuperAdmin />
      <AppDrawer
        open={openDrawer}
        onOpenDrawer={() => setOpenDrawer(true)}
        onCloseDrawer={() => setOpenDrawer(false)}
        isSuperAdmin
      />
      <div className={classes.main}>
        <div className={classes.toolbar} />
        <TransitionGroup component={null}>
          <Slide direction="left" key={location.key} timeout={200}>
            <main className={classes.content}>{children}</main>
          </Slide>
        </TransitionGroup>
        <SuperAdminFab />
      </div>
    </div>
  )
}
