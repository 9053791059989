import firebase from "config/firebase.config"
import { CustomAxiosRespone, publicAxios } from "config/axios"

export type UserFormData = {
  password: string
  email: string
  displayName: string
}

export default class AuthenticationService {
  static async createUserOnFirebase(userInfo: UserFormData): Promise<void> {
    await firebase
      .auth()
      .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
    const { currentUser } = firebase.auth()

    if (currentUser) {
      await currentUser.sendEmailVerification()
      await currentUser.updateProfile({
        displayName: userInfo.displayName,
      })
    }
  }

  static logIn({ email, password }: { email: string; password: string }) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  static async logOut(): Promise<void> {
    return firebase.auth().signOut()
  }

  static async createAndAssociateUser(companyNit: string) {
    const { currentUser } = firebase.auth()
    return publicAxios.post<User, CustomAxiosRespone<User>>("/users", {
      displayName: currentUser?.displayName,
      email: currentUser?.email,
      phoneNumber: currentUser?.phoneNumber || null,
      photoURL: currentUser?.photoURL || null,
      providerId: currentUser?.providerData[0]?.providerId,
      uid: currentUser?.uid,
      role: "seller",
      companyNit,
    })
  }

  static getUserFromDatabaseByUid(
    userUid: string | null,
  ): Promise<CustomAxiosRespone<User>> | null {
    if (userUid) {
      return publicAxios.get<User, CustomAxiosRespone<User>>(
        `/users/${userUid}`,
      )
    }

    return null
  }
}
