export default {
  CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",
  GET_COMPANIES_REQUEST: "GET_COMPANIES_REQUEST",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE: "GET_COMPANIES_FAILURE",
  GET_COMPANY_BY_UID_REQUEST: "GET_COMPANY_BY_UID_REQUEST",
  GET_COMPANY_BY_UID_SUCCESS: "GET_COMPANY_BY_UID_SUCCESS",
  GET_COMPANY_BY_UID_FAILURE: "GET_COMPANY_BY_UID_FAILURE",
}
