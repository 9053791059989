import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    title: {
      margin: theme.spacing(2, 0, 2, 0),
      textAlign: "center",
    },
  }),
)
