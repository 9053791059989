import { combineReducers } from "redux"
import superAdminReducer, {
  SuperAdminState,
} from "core/reducers/superAdmin.reducers"
import companyReducer, {
  CompanyStateType,
} from "core/reducers/company.reducers"
import loadingReducer from "./loading.reducers"
import errorReducer from "./error.reducers"
import inventoryItemsReducers, { MedicineStore } from "./medicine.reducers"

import REINIT_STORE from "../action-types/reinit.actionTypes"

export interface StateType {
  loadingStore: unknown
  errorStore: unknown
  superAdminStore: SuperAdminState
  companyStore: CompanyStateType
  inventoryItemsStore: MedicineStore
}

const appReducers = combineReducers({
  loadingStore: loadingReducer,
  errorStore: errorReducer,
  superAdminStore: superAdminReducer,
  companyStore: companyReducer,
  inventoryItemsStore: inventoryItemsReducers,
})

const rootReducer = (state: any, action: any) => {
  let localState = { ...state }
  if (action.type === REINIT_STORE) {
    localState = undefined
  }
  return appReducers(localState, action)
}

export default rootReducer
