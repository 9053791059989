import { useNavigate } from "@reach/router"
import { useFeedbackMessagesContext } from "components/feedback-message/FeedbackMessage"
import useAuthenticationContext from "core/contexts/Authentication.context"
import AuthenticationService from "core/services/auth.services"
import CompanyServices from "core/services/company.services"
import { FormEvent, useCallback, useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"

export default function useAssociateCompanyHooks() {
  const [shouldRegister, setShouldRegister] = useState(false)
  const [shouldAssociateNit, setShouldAssociateNit] = useState(false)
  const [nit, setNit] = useState("")
  const navigate = useNavigate()
  const { setState: setUserState } = useAuthenticationContext()
  const { setFeedbackMessagesState } = useFeedbackMessagesContext()
  const verifyQuery = useQuery({
    queryKey: ["verifyNit", nit || ""],
    queryFn: () => CompanyServices.verifyCompany(nit),
    enabled: !!nit,
    retry: false,
  })
  const createUserMutation = useMutation({
    mutationFn: AuthenticationService.createAndAssociateUser,
    mutationKey: ["createUserWithCommpanyNit", nit],
    onSuccess: axiosData => {
      setUserState(previousState => ({
        ...previousState,
        isAuthenticated: true,
        data: {
          ...(previousState.data as User),
          ...axiosData.data.data,
        },
      }))
      navigate("/")
      setFeedbackMessagesState({
        open: true,
        message: "Cuenta creada correctamente",
        variant: "success",
      })
    },
  })

  const verifyNit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const nitElement: HTMLInputElement = event.currentTarget.elements.namedItem(
      "nit",
    ) as HTMLInputElement
    setNit(nitElement.value || "")
  }

  useEffect(
    useCallback(() => {
      if (!verifyQuery.isLoading && verifyQuery.isSuccess) {
        if (verifyQuery.data.status === 204) {
          setShouldRegister(false)
          setShouldAssociateNit(true)
          createUserMutation.mutate(nit)
        }
      }
    }, [createUserMutation, nit, verifyQuery]),
    [verifyQuery.isSuccess, verifyQuery.isError],
  )

  return {
    isLoading: verifyQuery.isLoading || createUserMutation.isLoading,
    shouldRegister,
    shouldAssociateNit,
    verifyNit,
  }
}
