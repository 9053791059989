import { BigNumber } from "bignumber.js"

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "COP",
  currencyDisplay: "narrowSymbol",
  currencySign: "accounting",
})

Object.freeze(numberFormatter)

export default function formatAmount(amount: BigNumber): string {
  return numberFormatter.format(amount.toNumber())
}
