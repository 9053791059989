export type CurrentEnv = {
  apiUrl: string
}

const envConstants = {
  development: {
    apiUrl: "http://localhost:8080",
  },
  test: {
    apiUrl: "",
  },
  production: {
    apiUrl: "https://api.casaverde.com.co",
  },
}

const currentEnv: CurrentEnv =
  envConstants[process.env.NODE_ENV] || envConstants.development

export default currentEnv
