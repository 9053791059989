import { StateType } from "core/reducers"
import { CompanyType } from "models/companies.model"

export default function getCreatedCompany(state: StateType) {
  return state.companyStore.created
}

export function getCompanies(state: StateType): CompanyType[] | null {
  return state.companyStore.companies
}

export function getCompanyForUser(state: StateType): CompanyType | null {
  return state.companyStore.companyForUser
}
