import firebase from "firebase/app"
import firebaseConfig from "config/constants/firebase"
import "firebase/analytics"
import "firebase/auth"

const firebaseApp = firebase.initializeApp(firebaseConfig, "dessert-storm")

if (process.env.NODE_ENV === "development") {
  firebaseApp.auth().useEmulator("http://localhost:5003")
}

export default Object.freeze(firebaseApp)
