import { CompanyType } from "models/companies.model"
import createReducer from "core/factories/createReducer.factory"
import companyActionTypes from "core/action-types/company.actionTypes"

export type CompanyStateType = {
  created: CompanyType | null
  companies: CompanyType[] | null
  edited: CompanyType | null
  companyForUser: CompanyType | null
}

export const initialState: CompanyStateType = {
  created: null,
  edited: null,
  companies: null,
  companyForUser: null,
}

function createdCompany(state: CompanyStateType, { payload }: any) {
  return {
    ...state,
    created: payload,
  }
}

function companies(state: CompanyStateType, { payload }: any) {
  return {
    ...state,
    companies: payload,
  }
}

function companyForUser(state: CompanyStateType, { payload }: any) {
  return {
    ...state,
    companyForUser: payload,
  }
}

export default createReducer(initialState, {
  [companyActionTypes.CREATE_COMPANY_SUCCESS]: createdCompany,
  [companyActionTypes.GET_COMPANIES_SUCCESS]: companies,
  [companyActionTypes.GET_COMPANY_BY_UID_SUCCESS]: companyForUser,
})
