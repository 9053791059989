import React from "react"
import useStyles from "./FullScreenLoader.styles"

export default function FullScreenLoader() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.ripple}>
        <div />
        <div />
      </div>
    </div>
  )
}
