import React from "react"
import {
  Link as RouterLink,
  Redirect,
  RouteComponentProps,
} from "@reach/router"
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { useForm } from "react-hook-form"

import useLoginHooks from "modules/login/hooks/useLogin.hook"
import useAuthenticationContext from "core/contexts/Authentication.context"
import useStyles from "./Login.styles"
import loginValidations from "./loginValidations"

export type ILoginProps = RouteComponentProps

type FormData = {
  email: string
  password: string
}

export default function Login(props: ILoginProps) {
  const classes = useStyles()
  const { user } = useAuthenticationContext()
  const { onSubmitLogin, isLoading } = useLoginHooks()

  const formFunctions = useForm<FormData>({
    validationSchema: loginValidations,
  })

  const onSubmit = formFunctions.handleSubmit((data: FormData) => {
    onSubmitLogin(data.email, data.password)
  })

  if (user.isAuthenticated) {
    return <Redirect noThrow to="/" />
  }

  return (
    <div>
      <Typography component="h1" variant="h4" className={classes.title}>
        Iniciar Sesión
      </Typography>
      <form noValidate onSubmit={onSubmit}>
        <TextField
          inputRef={formFunctions.register}
          error={Boolean(formFunctions.errors.email)}
          helperText={formFunctions.errors.email?.message}
          disabled={isLoading}
          variant="outlined"
          margin="normal"
          id="email"
          label="Correo Electrónico"
          name="email"
          autoComplete="email"
          required
          fullWidth
          autoFocus
        />
        <TextField
          inputRef={formFunctions.register}
          error={Boolean(formFunctions.errors.password)}
          helperText={formFunctions.errors.password?.message}
          disabled={isLoading}
          variant="outlined"
          margin="normal"
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          required
          fullWidth
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? (
            <CircularProgress size={28} color="inherit" />
          ) : (
            "Iniciar sesión"
          )}
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              component={RouterLink}
              to="password-forgotten"
              variant="body2"
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to="/auth/create-account"
              variant="body2"
            >
              Crear cuenta
            </Link>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
