import React, { useCallback, useEffect } from "react"
import { Link, RouteComponentProps } from "@reach/router"
import { Typography, Avatar, IconButton } from "@material-ui/core"
import {
  LocalHospitalRounded,
  DeleteRounded,
  EditRounded,
} from "@material-ui/icons"
import DataGrid, { Column, Row, RowRendererProps } from "react-data-grid"
import { MedicineAttributes } from "core/reducers/medicine.reducers"
import classNames from "classnames"
import formatAmount from "core/factories/currencyFormatter.factory"
import { useMedicineActions, useMedicineStore } from "./medicine.hook"
import useStyles, { useNameCellStyles } from "./MedicinePage.style"

export interface Item {
  id: string
  name: string
  ean: string | null
  laboratory: string
  qty: number
  iva: string
  image: string | null
  internalCode: number
  buyingPrice: string
  sellingPrice: string
}

function isAtbottom(event: React.UIEvent<HTMLDivElement>): boolean {
  const target = event.target as HTMLDivElement
  return target.clientHeight + target.scrollTop === target.scrollHeight
}

function itemsMapper(items: MedicineAttributes[]): Item[] {
  return items.map(item => {
    const buyingPrice = formatAmount(item.buyingPrice)
    const sellingPercentage = item.sellingPercentage || 0
    const winnigEstimate = item.buyingPrice
      .dividedBy(100)
      .multipliedBy(sellingPercentage)
    const ivaEstimate = item.buyingPrice
      .dividedBy(100)
      .multipliedBy(item.iva.value)
    const sellingPrice = item.buyingPrice.plus(winnigEstimate).plus(ivaEstimate)
    return {
      id: item.id,
      name: (() => {
        const presentation = item.presentation
          ? ` ${item.presentation.name}`
          : ""
        return (item.name + presentation).toLowerCase()
      })(),
      ean: item.ean,
      laboratory: item.laboratory ? item.laboratory.name : "",
      qty: item.qty,
      iva: item.iva ? `${item.iva.value}%` : "",
      image: item.image,
      internalCode: item.internalCode,
      buyingPrice,
      sellingPrice: formatAmount(sellingPrice),
    }
  })
}

export default function ItemsPage(props: RouteComponentProps) {
  const actions = useMedicineActions()
  const store = useMedicineStore()
  const classes = useStyles()
  const nameCellStyles = useNameCellStyles()

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (!isAtbottom(event)) {
        return
      }

      if (store.medicineItems.length + 1 >= store.startAt) {
        actions.getMedicine()
      }
    },
    [actions, store.medicineItems.length, store.startAt],
  )

  useEffect(
    useCallback(() => {
      actions.getMedicine()
    }, [actions]),
    [store.companyForuser?.id],
  )

  const rows = itemsMapper(store.medicineItems)

  const columns: readonly Column<Item>[] = [
    {
      name: "Imagen",
      key: "image",
      width: 80,
      formatter: function MedicineImageFormatter(imageFormatterProps) {
        const { row: item } = imageFormatterProps
        return (
          <Avatar alt={item.name} src={item.image || ""}>
            <LocalHospitalRounded />
          </Avatar>
        )
      },
      cellClass: classNames(classes.cell, nameCellStyles.root),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Código",
      key: "internalCode",
      width: 80,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Nombre",
      key: "name",
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
      formatter: function MedicineNameFormatter(formatterProps) {
        const { row: item } = formatterProps
        return (
          <div className={nameCellStyles.root}>
            <div className={nameCellStyles.nameContainer}>
              <Typography
                className={nameCellStyles.name}
                gutterBottom={false}
                variant="subtitle1"
              >
                {item.name}
              </Typography>
            </div>
            <div className={nameCellStyles.info}>
              {item.internalCode && (
                <Typography variant="caption">{`COD: ${item.internalCode}`}</Typography>
              )}
              {item.ean && (
                <Typography variant="caption">{`EAN: ${item.ean}`}</Typography>
              )}
              {item.laboratory && (
                <Typography variant="caption">{`LAB: ${item.laboratory.toLowerCase()}`}</Typography>
              )}
            </div>
          </div>
        )
      },
    },
    {
      name: "Cant.",
      key: "qty",
      width: 70,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "IVA",
      key: "iva",
      width: 100,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Precio Costo",
      key: "buyingPrice",
      width: 150,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Precio de Venta",
      key: "sellingPrice",
      width: 150,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Notas",
      key: "notas",
      width: 150,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
    },
    {
      name: "Acciones",
      key: "",
      width: 150,
      cellClass: classNames(classes.cell),
      headerCellClass: classNames(classes.headerCell),
      formatter: function ActionsFormatter(actionsFormatterProps) {
        const { row } = actionsFormatterProps
        return (
          <div>
            <IconButton component={Link} to={`edit/${row.id}`}>
              <EditRounded />
            </IconButton>
            <IconButton
              onClick={() => {
                console.log(row)
              }}
            >
              <DeleteRounded />
            </IconButton>
          </div>
        )
      },
    },
  ]

  const CustomRow = (rowProps: RowRendererProps<Item>) => {
    const { className, ...otherProps } = rowProps
    return (
      <Row {...otherProps} className={classNames(classes.gridRow, className)} />
    )
  }

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Medicina
      </Typography>
      {store.medicineItems.length > 0 && (
        <DataGrid
          columns={columns}
          rows={rows}
          rowRenderer={CustomRow}
          rowKeyGetter={i => i.id}
          className={classes.grid}
          onScroll={handleScroll}
          headerRowHeight={35}
          rowClass={() => classes.gridRow}
          rowHeight={60}
        />
      )}
    </div>
  )
}
