type FirebaseCodeErrors = {
  [type: string]: string
}

const firebaseCodeErrors: FirebaseCodeErrors = {
  "auth/email-already-in-use": "El correo ingresado está en uso",
  "auth/user-not-found": "El correo ingresado no existe",
  "auth/wrong-password": "La contraseña ingresada no es correcta",
}

export default firebaseCodeErrors
