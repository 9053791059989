import globalAxios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import currentEnv from "config/constants/environment.constants"
import firebaseConfig from "config/firebase.config"

export type CustomAxiosRespone<TResponse = unknown> = AxiosResponse & {
  data: TResponse
  apiMessageStatus: string
}

function responseInterceptor(response: AxiosResponse): CustomAxiosRespone {
  return {
    ...response,
    data: response.data.data,
    apiMessageStatus: response.data.status,
  }
}

async function requestInterceptor(
  request: AxiosRequestConfig,
): Promise<AxiosRequestConfig> {
  const currentUser = await firebaseConfig.auth().currentUser

  if (currentUser) {
    return {
      ...request,
      headers: {
        Authorization: `Bearer ${await currentUser.getIdToken()}`,
      },
    }
  }

  return request
}

const axios: AxiosInstance = globalAxios.create({
  baseURL: currentEnv.apiUrl,
})

export const publicAxios: AxiosInstance = globalAxios.create({
  baseURL: currentEnv.apiUrl,
})

axios.interceptors.response.use(responseInterceptor)
axios.interceptors.request.use(requestInterceptor)

export default axios
