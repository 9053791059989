import React from "react"
import { Link as MaterialLink } from "@material-ui/core"
import { Link, RouteComponentProps } from "@reach/router"

type DashboardContainerProps = RouteComponentProps

export default function DashboardContainer(props: DashboardContainerProps) {
  return (
    <div>
      <h1>Dashboard</h1>
      <MaterialLink
        component={Link}
        style={{ fontSize: "2rem" }}
        to="/inventory/medicine"
      >
        Ver Medicina
      </MaterialLink>
    </div>
  )
}
