import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(45),
      borderRadius: 0,
      height: "auto",
    },
    main: {
      flex: 1,
    },
  }),
)
