import React from "react"
import { Router } from "@reach/router"
import { PublicLayout, MainLayout } from "components/layouts"
import CreateAccountPage from "modules/create-account/components/CreateAccount"
import SuperAdminLayout from "components/layouts/super-admin/SuperAdmin.layout"
import { CompaniesListPage, CreateCompanyPage } from "modules/company"
import LoginPage from "modules/login/components/Login"
import DashboardContainer from "modules/dashboard/containers/Dashboard.container"
import UserListPage from "modules/user"
import AccountCreatedSuccessfully from "modules/create-account/components/AccountCreatedSuccessfully"
import AssociateCompany from "modules/create-account/components/AssociateCompany"
import InventoryRouter from "./Inventory.router"

export default function ReachRouter() {
  return (
    <Router>
      <PublicLayout path="/auth">
        <LoginPage default path="/login" />
        <CreateAccountPage path="/create-account" />
        <AssociateCompany path="/create-account/associate-company" />
        <AccountCreatedSuccessfully path="/create-account/success" />
      </PublicLayout>
      <MainLayout path="/">
        <DashboardContainer path="/" />
        <InventoryRouter path="/inventory/*" />
      </MainLayout>
      <SuperAdminLayout path="/super-admin/">
        <CompaniesListPage default path="company" />
        <CreateCompanyPage path="company/create" />
        <UserListPage path="users" />
      </SuperAdminLayout>
    </Router>
  )
}
