import errorMessages from "config/errorMessages"
import * as yup from "yup"

export default yup.object().shape({
  email: yup
    .string()
    .required(errorMessages.required)
    .email(errorMessages.email),
  password: yup.string().required(errorMessages.required),
})
